// import { PieCard } from "components/pieCard/pieCard.component"
import { Page } from "components/page/page.component"
import style from './dashboard.module.css'
import { View } from "components/view/view.component"
import { Text } from 'components/text/text.component'
import { PieCard } from "components/pieCard/pieCard.component"
import { Filters } from "components/filters/filters.component"
import { FilterType } from "model/filter.model"
import { useEffect, useState } from "react"
import { useGetDashboardDataApi } from "api/dashboard.api"
import { DashboardDataType } from "model/dashboardData.model"
import { LineChartCard } from "components/lineChartCard/lineChartCard.component"
import { ColorRange } from "model/colorRange.model"
import { toMonthYearString } from "utils/date.util"

interface DashboardPageProps {
    companyId?: string
}

export function DashboardPage({companyId}: DashboardPageProps){
    const {get: getDashboardData, data: dashboardData, loading} = useGetDashboardDataApi(companyId ?? '')
    const [filter, setFilter] = useState<FilterType | undefined>()

    const dashboardDataByBuilding = filter?.buildings?.map(building => {
        return { name: building.name, data: filterDashboardDatasByBuildingId( building.id, dashboardData) }
    })

    function filterDashboardDatasByBuildingId(
        buildingId: string,
        dashboardDatas?: DashboardDataType,
    ): DashboardDataType {
        if(!dashboardDatas) return []
        return dashboardDatas.map(data => {
            const filteredRecords = data.indicatorRecords.filter(record => record.building.id === buildingId);
            return {
                ...data,
                indicatorRecords: filteredRecords
            };
        }).filter(data => data.indicatorRecords.length > 0); // Remove entries with no indicatorRecords
    }

    useEffect(()=>{
        if(companyId && filter?.buildings && filter.buildings.length > 0) getIndicatorRecords()
    // eslint-disable-next-line
    }, [filter, companyId])

    function handleOnFilterChange(filter: FilterType){
        setFilter(filter)
    }

    function getIndicatorRecords(){
        const _filter = {
            companyId: companyId ?? '',
            buildings: filter?.buildings?.map(_ => _.id) ?? [],
            indicators: filter?.dashboardIndicators?.map(_ => {return {type: _.type, indicator: _.indicator.id}}) ?? [],
            date: filter?.date
        }

        getDashboardData(_filter).catch(()=>{})
    }

    function indentifyColor(colorRanges?: ColorRange[], value?: number){
        if (!colorRanges || value === undefined) return undefined
    
        for (const range of colorRanges) {
            const minCheck = range.min === null || value >= range.min
            const maxCheck = range.max === null || value <= range.max
            if (minCheck && maxCheck) {
                return range.color
            }
        }
    
        return undefined
    }

    function moreThanOneBuilding(){
        return dashboardDataByBuilding?.length && dashboardDataByBuilding.length > 1
    }

    function includesTotal(){
        return dashboardData?.map(_ => _.type === 'card' && (_.indicator.accumulated === 'sum' || _.indicator.accumulated === 'average' || _.indicator.accumulated === 'divisionOfSums')).includes(true)
    }


    return <Page menu header title="Dashboard">
        <View className={style.PageContent}>
            <View className={style.PageHeader}>
                <Filters companyId={companyId} showBuildings showBuildingGroups showDate showDashboardIndicators indicatorsFor='dashboard' onChange={handleOnFilterChange} />
            </View>
            {!filter?.buildings || filter.buildings.length <= 0 ? 
            <View className={style.IndicatorsContent}>
                <Text type="h3">Selecione ao menos um prédio</Text>
            </View>
            : <></>
            }
            {(dashboardDataByBuilding && dashboardDataByBuilding.length > 0) && <View className={style.IndicatorsContent}>
                {dashboardDataByBuilding?.map(buildingsData => {
                    return <View className={style.IndicatorByBuildingContent}>
                    <Text type="h4">{buildingsData.name}</Text>
                    {
                    loading
                     ?
                     <View className={style.Indicators}>
                        <PieCard loading/> 
                        <PieCard loading/> 
                        <PieCard loading/> 
                        <PieCard loading/>
                        <PieCard loading/>
                        <PieCard loading/>
                     </View>
                     :
                    <View className={style.Indicators}>
                        {buildingsData.data?.map((_, index) => {
                            const pendingRecords = Array.from(new Set(_.indicatorRecords[0].pending?.map(p => `${p.indicator.name} - ${toMonthYearString(p.date)}`)))
                            if(_.type === 'card') return <PieCard 
                            key={index} 
                            value={_.indicatorRecords[0].value} 
                            color={indentifyColor(_.indicator.colorRanges,_.indicatorRecords[0].value)} 
                            max={ _.indicator.unit === '%' ? 100 :  _.indicatorRecords[0].value} 
                            unit={_.indicator.unit} 
                            title={_.indicator.name}
                            hoverText={`Indicadores pendentes: \n` + (pendingRecords.length > 0 ? pendingRecords.join(',\n') : 'Nenhum indicador pendente')}
                            />
                            else if(_.type === 'line-chart') return <LineChartCard key={index} title={`${_.indicator.name}${` (${_.indicator.unit})`}`} indicatorRecords={_.indicatorRecords} />
                            return undefined
                        })}
                    </View>
                    }
                </View>
                })}
                {(moreThanOneBuilding() && includesTotal()) && <View className={[style.IndicatorByBuildingContent, style.IndicatorByBuildingContentFooter].join(' ')}>
                    <Text type="h4">Total</Text>
                    <View className={style.Indicators}>
                            {dashboardData?.map((_, index) => {
                                // const pendingRecords = Array.from(new Set(_.indicatorRecords[0].pending?.map(p => `${p.indicator.name} - ${toMonthYearString(p.date)}`)))
                                const total = _.indicatorRecords.map(record => record.value).reduce((acc, value) => acc + (value ?? 0), 0)
                                
                                const media = total / _.indicatorRecords.length
                                console.log(`total`, _.indicator.name, total)
                                if(_.type === 'card' && (_.indicator.accumulated === 'sum' || _.indicator.accumulated === 'average' || _.indicator.accumulated === 'divisionOfSums')) return <PieCard 
                                key={index} 
                                // value={_.indicator.unit === '%' ? media : total} 
                                value={_.total}
                                // color={indentifyColor(_.indicator.colorRanges,_.indicatorRecords[0].value)} 
                                max={ _.indicator.unit === '%' ? 100 :  total} 
                                unit={_.indicator.unit} 
                                title={_.indicator.name}
                                // hoverText={`Indicadores pendentes: \n` + (pendingRecords.length > 0 ? pendingRecords.join(',\n') : 'Nenhum indicador pendente')}
                                />
                                // else if(_.type === 'line-chart') return <LineChartCard key={index} title={`${_.indicator.name}${` (${_.indicator.unit})` ?? ''}`} indicatorRecords={_.indicatorRecords} />
                                return undefined
                            })}
                    </View>
                </View>}
            </View>}
        </View> 
    </Page>
}